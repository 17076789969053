import * as React from 'react'

const verysmallStyle = { height: "2em" };
const smallStyle = { height: "5em" };
const mediumStyle = { height: "10em" };
const largeStyle = { height: "20em" };

export default ({ verysmall, small, medium, large, id }) => {
    let trenner = <section id={id}><div style={verysmallStyle}/></section>

    if (small)
        trenner = <section id={id}><div style={smallStyle}/></section>
    if (medium)
        trenner = <section id={id}><div style={mediumStyle}/></section>
    if (large)
        trenner = <section id={id}><div style={largeStyle}/></section>

    return trenner;
}