import React, { Component } from "react";
import {Trans} from "gatsby-plugin-react-i18next";

import "../components/contactFlyingButton.css";

class FlyingContactClick extends Component {
  // constructor to set state and bind "this"
  constructor(props) {
    super(props);
    this.state = { isToggleOn: false };
    this.handleClick = this.handleClick.bind(this);
  }

  // function to handle the click
  handleClick() {
    if (!this.state.isToggleOn) // nur beim öffnen
      window._paq.push([`trackEvent`, 'UI', 'view', 'contactFly', 'open']); // Matomo event tracking

    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  }

  render() {
      // defaults closed, no infos
    let buttonState = (
        <div>
          <div className={"contact-big-button"}>
              <div className={"kontakt_text"}>KONTAKT</div>
          </div>
          <div className={"contact-hidden-content"}/>
        </div>
    );

    if (this.state.isToggleOn) {
        buttonState = (
            <div>
                <div className={"contact-big-button_active"}>
                    <div className={"kontakt_text"}>KONTAKT</div>
                </div>
                <div className={"contact-hidden-content_active"}>
                    <a href="tel:+49 345 13514759"> +49 345 13514759</a>
                    <br />
                    <a href="mailto:kontakt@denkundmach.de" role="button">
                        kontakt@denkundmach.de
                    </a>
                </div>
            </div>
        )
    }

    return (
      <div id='contact-fly' className={"contactHolder"} >
        <div className="about">
          <div className="button" onClick={this.handleClick} role='button' tabIndex={0} onKeyPress={this.handleClick}>
            { buttonState }
          </div>
        </div>
      </div>
    );
  }
}

export default FlyingContactClick;
